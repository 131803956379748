import React from 'react';

const cart = props => {
  return (
    <div>
      <h1>cart</h1>
    </div>
  )
}

export default cart;


// import React from "react"
// import { graphql } from "gatsby"
// import {
//   useCart,
//   useCartItems,
//   useRemoveItemFromCart,
//   useCheckoutUrl,
// } from "gatsby-theme-shopify-manager"
// import Img from "gatsby-image"

// import Layout from "../components/Layout/Layout"
// import SEO from "../components/SEO/SEO"

// import "./cart.scss"
// import LinkButton from "../components/LinkButton/LinkButton"

// export const CART_QUERY = graphql`
//   {
//     products: allShopifyProduct {
//       totalCount
//       edges {
//         node {
//           id
//           title
//           handle
//           variants {
//             shopifyId
//             price
//           }
//           images {
//             localFile {
//               childImageSharp {
//                 fixed(width: 150, height: 150) {
//                   ...GatsbyImageSharpFixed
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

// const Cart = ({ data }) => {
//   const cart = useCart()
//   const cartItems = useCartItems()
//   const removeItemFromCart = useRemoveItemFromCart()
//   const checkoutUrl = useCheckoutUrl()

//   const getFullItem = variantId => {
//     const item = data.products.edges.find(({ node: item }) => {
//       return variantId === item.variants[0].shopifyId
//     })
//     return item.node
//   }

//   const handleRemoveItem = async variantId => {
//     try {
//       await removeItemFromCart(variantId)
//       alert("Item removed successfully")
//     } catch {
//       alert("There was an error removing the item")
//     }
//   }

//   let displayItems
//   if (!cartItems || !cartItems.length) {
//     displayItems = <p>There are no items in your cart.</p>
//   } else {
//     displayItems = cartItems.map(item => {
//       const fullItem = getFullItem(item.variant.id)
//       const fixed = fullItem.images[0].localFile.childImageSharp.fixed

//       return (
//         <div className="cart-item">
//           <Img className="cart-item__image" fixed={fixed} />

//           <h3 className="cart-item__title cart-item__text">{item.title}</h3>
//           <p className="cart-item__quantity cart-item__text">
//             <span className="cart-item__quantity-key ">Quantity:</span>
//             {item.quantity}
//           </p>

//           <button
//             className="cart-item__delete"
//             onClick={() => handleRemoveItem(item.variant.id)}
//           >
//             Delete
//           </button>
//           <p className="cart-item__price cart-item__text">
//             ${(+fullItem.variants[0].price * item.quantity).toFixed(2)}
//           </p>
//         </div>
//       )
//     })
//   }

//   return (
//     <Layout>
//       <SEO title="home" />
//       {cart && (
//         <div className="cart">
//           <div className="cart-items">{displayItems}</div>
//           <div className="cart-summary">
//             <div>
//               <div className="cart-summary__box">
//                 <div className="cart-summary__box-item">
//                   <h4 className="cart-summary__key">Subtotal:</h4>
//                   <span>${cart.subtotalPrice}</span>
//                 </div>
//                 <div className="cart-summary__box-item">
//                   <h4 className="cart-summary__key">Taxes:</h4>
//                   <span>Included</span>
//                 </div>
//                 <div className="cart-summary__box-item">
//                   <h4 className="cart-summary__key">Shipping:</h4>
//                   <span>Local Pickup</span>
//                 </div>
//                 <hr />
//                 <div className="cart-summary__box-item">
//                   <h4 className="cart-summary__key">Total:</h4>
//                   <span>${cart.totalPrice}</span>
//                 </div>
//               </div>
//               <LinkButton>
//                 <a
//                   className="cart-summary__checkout-button"
//                   href={checkoutUrl}
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   Checkout
//                 </a>
//               </LinkButton>
//             </div>
//           </div>
//         </div>
//       )}
//     </Layout>
//   )
// }

// export default Cart

// // import React from "react"
// // import { useStaticQuery, graphql } from "gatsby"
// // import { Styled } from "theme-ui"
// // import Img from "gatsby-image"
// // import { Grid, Divider, Button, Card, Text } from "@theme-ui/components"

// // import Layout from "../components/Layout"
// // import SEO from "../components/SEO"
// // import Link from "../components/Link"

// // import {
// //   useAddItemToCart,
// //   useCartItems,
// //   useCheckoutUrl,
// //   useCart,
// //   useUpdateItemQuantity,
// // } from "gatsby-theme-shopify-manager"

// // const CartPage = () => {
// //   const {
// //     allShopifyProductVariant: { nodes: variants },
// //     allShopifyProduct: { nodes: products },
// //   } = useStaticQuery(graphql`
// //     query {
// //   allShopifyProductVariant {
// //     nodes {
// //       shopifyId
// //       image {
// //         localFile {
// //           childImageSharp {
// //             fluid(maxWidth: 120) {
// //               ...GatsbyImageSharpFluid_withWebp
// //             }
// //           }
// //         }
// //       }
// //     }
// //   }
// //   allShopifyProduct {
// //     nodes {
// //       handle
// //       variants {
// //         shopifyId
// //       }
// //     }
// //   }
// // }
// //   `)

// //   const lineItems = useCartItems()
// //   const updateItemQuantity = useUpdateItemQuantity()
// //   const checkoutUrl = useCheckoutUrl()
// //   console.log(checkoutUrl)
// //   const cart = useCart()
// //   const { tax, total } = getCartTotals(cart)
// //   const addItemToCart = useAddItemToCart()

// //   const betterProductHandles = products.map(({ handle, variants }) => {
// //     const newVariants = variants.map(variant => variant.shopifyId)
// //     return {
// //       variants: newVariants,
// //       handle,
// //     }
// //   })

// //   function getCartTotals(cart) {
// //     if (cart == null) {
// //       return { tax: "-", total: "-" }
// //     }

// //     const tax = cart.totalTaxV2
// //       ? `$${Number(cart.totalTaxV2.amount).toFixed(2)}`
// //       : "-"
// //     const total = cart.totalPriceV2
// //       ? `$${Number(cart.totalPriceV2.amount).toFixed(2)}`
// //       : "-"

// //     return {
// //       tax,
// //       total,
// //     }
// //   }

// //   async function removeFromCart(variantId) {
// //     try {
// //       await updateItemQuantity(variantId, 0)
// //     } catch (e) {
// //       console.log(e)
// //     }
// //   }

// //   function getHandleForVariant(variantId) {
// //     const selectedProduct = betterProductHandles.find(product => {
// //       return product.variants.includes(variantId)
// //     })

// //     return selectedProduct ? selectedProduct.handle : null
// //   }

// //   function getImageFluidForVariant(variantId) {
// //     const selectedVariant = variants.find(variant => {
// //       return variant.shopifyId === variantId
// //     })

// //     if (selectedVariant) {
// //       return selectedVariant.image.localFile.childImageSharp.fluid
// //     }
// //     return null
// //   }

// //   const LineItem = ({ item }) => (
// //     <div
// //       sx={{
// //         display: "grid",
// //         gridGap: "15px",
// //         gridTemplateColumns: "120px 2fr 80px 80px",
// //         alignItems: "center",
// //       }}
// //     >
// //       <div>
// //         <div sx={{ padding: 1, border: "1px solid gray" }}>
// //           <Img fluid={getImageFluidForVariant(item.variant.id)} />
// //         </div>
// //       </div>
// //       <div>
// //         <Link
// //           url={`/product/${getHandleForVariant(item.variant.id)}`}
// //           sx={{ fontSize: 3, m: 0, fontWeight: 700 }}
// //         >
// //           {item.title}
// //         </Link>
// //         <Styled.ul sx={{ mt: 2, mb: 0, padding: 0, listStyle: "none" }}>
// //           {item.variant.selectedOptions.map(({ name, value }) => (
// //             <li key={name}>
// //               <strong>{name}: </strong>
// //               {value}
// //             </li>
// //           ))}
// //           <li key="quantity">
// //             <strong>Quantity: </strong>
// //             {item.quantity}
// //           </li>
// //         </Styled.ul>
// //       </div>
// //       <Button variant="link" onClick={() => removeFromCart(item.variant.id)}>
// //         Delete
// //       </Button>
// //       <Text
// //         sx={{
// //           fontSize: 4,
// //           fontWeight: 700,
// //           marginLeft: "auto",
// //         }}
// //       >
// //         ${Number(item.variant.priceV2.amount).toFixed(2)}
// //       </Text>
// //     </div>
// //   )

// //   const emptyCart = (
// //     <Layout>
// //       <SEO title="Cart" />
// //       <Styled.h1>Cart</Styled.h1>
// //       <Styled.p>Your shopping cart is empty.</Styled.p>
// //       <Button
// //         sx={{ mt: 4 }}
// //         onClick={() =>
// //           addItemToCart(
// //             variants[Math.floor(Math.random() * (variants.length - 1))]
// //               .shopifyId,
// //             1
// //           )
// //         }
// //       >
// //         <span role="img" aria-label="Dice Emoji">
// //           🎲
// //         </span>{" "}
// //         Random item plz
// //       </Button>
// //     </Layout>
// //   )

// //   return lineItems.length < 1 ? (
// //     emptyCart
// //   ) : (
// //     <Layout>
// //       <SEO title="Cart" />
// //       <Styled.h1>Cart</Styled.h1>
// //       {lineItems.map(item => (
// //         <React.Fragment key={item.id}>
// //           <LineItem key={item.id} item={item} />
// //           <Divider sx={{ my: 3 }} />
// //         </React.Fragment>
// //       ))}
// //       <div sx={{ display: "flex" }}>
// //         <Card sx={{ marginLeft: "auto", minWidth: "10rem", p: 4 }}>
// //           <Styled.h3 sx={{ mt: 0, mb: 3 }}>Cart Summary</Styled.h3>
// //           <Divider />

// //           <Grid gap={1} columns={2} sx={{ my: 3 }}>
// //             <Text>Subtotal:</Text>
// //             <Text sx={{ marginLeft: "auto" }}>{total}</Text>
// //             <Text>Shipping:</Text>
// //             <Text sx={{ marginLeft: "auto" }}> - </Text>
// //             <Text>Tax: </Text>
// //             <Text sx={{ marginLeft: "auto" }}>{tax}</Text>
// //           </Grid>

// //           <Divider />
// //           <Grid gap={1} columns={2}>
// //             <Text variant="bold">Estimated Total:</Text>
// //             <Text variant="bold" sx={{ marginLeft: "auto" }}>
// //               {total}
// //             </Text>
// //           </Grid>
// //           <br />
// //           {checkoutUrl != null ? (
// //             <a
// //               sx={{ mt: 4, width: "100%" }}
// //               href={checkoutUrl}
// //               target="_blank"
// //               rel="noopener noreferrer"
// //             >
// //               Checkout
// //             </a>
// //           ) : null}
// //         </Card>
// //       </div>
// //     </Layout>
// //   )
// // }

// // export default CartPage
